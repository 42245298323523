import React, { useState, useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { client, urlFor } from "../../client";
import "./Work.scss";
import DocumentMeta from "react-document-meta";
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker';

const Work = () => {
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [filterWorks, setFilterWorks] = useState([]);
  const [works, setWorks] = useState([]);
  const gaEventTracker = useAnalyticsEventTracker('Works');
  const [visibleItems, setVisibleItems] = useState(7);

  const [touched, setTouched] = useState(null);

  useEffect(() => {
    const query = '*[_type == "works"]';
    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWorks(data);
    });
  }, []);

  const handleFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard({ opacity: 0, scale: 0.5, y: -100 });


    setTimeout(() => {
      setAnimateCard({ opacity: 1, scale: 1, y: 0 });
      if (item === "All") {
        setFilterWorks(works);
      } else {
        setFilterWorks(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

 
  const meta = {
    title: 'Beimnet Zewdu - Portfolio Website',
    description: 'This is the portfolio website, of Beimnet Zewdu a mobile and web applications developer in Addis Ababa, Ethiopia.',
    canonical: 'https://beimnetzewdu.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'Beimnet, Beimnet Zewdu, Beimnet Zewdu Kebede, Mobile Developer, Web Developer, Website Developer, Website Design, Android Developer, Android App Developer, Mobile Development, Android Development, Android App Development, Best Mobile Developer, Best Mobile App Developer, Best Android App Developer, Best Web Developer,  Best Mobile Developer in Ethiopia, Best Mobile Developer in Addis, Best Mobile Developer in Addis Ababa, Best Mobile Developer in Addis Abeba, Best Web Developer in Ethiopia, Best Web Developer in Addis Ababa, Best Web Developer in Addis Abeba, Best Developer in Ethiopia, Best Developer in Addis Ababa, Beautiful Website, React Developer'
        }
    }
  };

  const filteredCounts = works.reduce((counts, work) => {
    work.tags.forEach((tag) => {
      counts[tag] = (counts[tag] || 0) + 1;
    });
    return counts;
  }, {});

  const handleShowMore = () => {
    if (visibleItems === filterWorks.length) {
      setVisibleItems(7); // Show 7 items
    } else {
      setVisibleItems(filterWorks.length); // Show all items
    }
    //setVisibleItems((prevVisibleItems) => prevVisibleItems + 7);
    /*const sectionElement = document.querySelector(".app__works");

    if (sectionElement) {
      // Get the top position of the section
      const sectionTop = sectionElement.offsetTop;
  
       Scroll to just above the section (adjust the value as needed)
      window.scrollTo({
        top: sectionTop + 700, // Adjust the value as needed
        behavior: "smooth",
      });
     
    }
  */
  };


  return (
    <>
       <DocumentMeta {...meta} />
      <h2 className="head-text">
      <span>Portfolio</span> & <span>Projects</span>
      </h2>

      <div className="app__work-filter">
        {["All", "Android", "ReactJS", "ThreeJS", "HTML5", "Joomla", "MVC", "PHP", "C#"].map(
          (item, index) => (
            <div
              key={index}
              onClick={() => handleFilter(item)}
              className={`app__work-filter-item app__flex p-text ${
                activeFilter === item ? "item-active" : ""
              }`}
            >
              {item} ({filteredCounts[item] || works.length})
            </div>
          )
        )}
      </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
        onClick={() => setTouched(null)}
      >
        {filterWorks.slice(0, visibleItems).map((work, index) => (
          <motion.div
            className={`app__work-item app__flex ${
              touched === index ? "app__work-touched-nowrap" : ""
            }`}
            key={index}
            onClick={() => setTouched(index)}
            onTouchStart={() => setTouched(index)}
          >
            <div className="app__work-img app__flex">
              <img src={urlFor(work.imgUrl)} alt={work.title} loading="lazy" />
              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className={`app__work-hover app__flex ${
                  touched === index ? "app__work-touched" : ""
                }`}
              >
                <a
                  href={work.projectLink}
                  target="_blank"
                  rel="noreferrer"
                  className={`app__flex ${ work.projectLink === "na" ? "none" : "" }`}
                  aria-label={`Visit ${work.title} project`} // Add accessible label
                  title={`Visit ${work.title} project`}    
                >
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
               {/* <a
                  href={work.codeLink}
                  target="_blank"
                  rel="noreferrer"
                  className="app__flex"
                >
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillGithub />
                  </motion.div>
                </a>
                */}
              </motion.div>
            </div>
            <div className="app__work-content app__flex">
              <h4 className="bold-text" title={work.title} style={{color: "#1b2598"}}>
                {work.title}
              </h4>
              <p
                className="p-text"
                style={{ marginTop: 10 }}
                title={work.description}
              >
                {work.description}
              </p>
              <div className="app__work-tag app__flex">
                <p className="p-text">{work.tags[0]}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
      {visibleItems < filterWorks.length && (
        <div id="container">
        <button class="show-more" onClick={handleShowMore}>
          <span class="circle" aria-hidden="true">
          <span class="icon arrow"></span>
          </span>
          <span className="button-text">
              {visibleItems === filterWorks.length ? "Show Less ⬆️" : "Show All ⬇️"}
            </span>
          {//<span class="button-text">Show {Math.min(7, filterWorks.length - visibleItems)} More</span>
}
        </button>
      </div>
       // <button className="show-more-button" onClick={handleShowMore}>
        //  Show {Math.min(6, filterWorks.length - visibleItems)} More   ⬇️
       // </button>
      )}
      
    </>
    
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "work",
  "app__primarybg"
);
